import { useQuery } from '@apollo/client';

import { useParams } from 'js/lib/useRouter';

import { IdTypes, createFeatureScope } from 'bundles/product-features';
import type {
  EnterpriseProgramBySlugQueryResult,
  EnterpriseProgramBySlugQueryVariables,
} from 'bundles/user-consent/queries/ThirdPartyOrgIdFromProgramSlug';
import { ThirdPartyOrgIdFromProgramSlugQuery } from 'bundles/user-consent/queries/ThirdPartyOrgIdFromProgramSlug';

const { useProductFeatures } = createFeatureScope({
  enterprise: {
    enableEnterpriseTermsOfUseModal: false, // Default value
  },
});

// Product feature toggle to opt out orgs https://www.coursera.org/internal/feature-toggles?name=enableOptimizedLearnerFlow&namespace=enterprise
const useEnterpriseTermsOfUseModal = (shouldSkipQuery = false) => {
  const { programSlug } = useParams();
  const { data, loading: isFetchingThirdPartyOrgId } = useQuery<
    EnterpriseProgramBySlugQueryResult,
    EnterpriseProgramBySlugQueryVariables
  >(ThirdPartyOrgIdFromProgramSlugQuery, {
    variables: { programSlug },
    context: { clientName: 'gatewayGql' },
    skip: shouldSkipQuery || !programSlug,
  });
  const thirdPartyOrgId = data?.EnterpriseProgramsV1?.slug?.linked?.thirdPartyOrganizationsV1?.[0].id;
  const { features, loading: areProductFeaturesLoading } = useProductFeatures({
    idType: IdTypes.Organization,
    id: thirdPartyOrgId ?? '', // Empty string since we skip below if no org id
    skip: shouldSkipQuery || !thirdPartyOrgId,
    ssr: true,
  });
  const isEnterpriseTermsOfUseModalEnabled = features.get('enterprise', 'enableEnterpriseTermsOfUseModal');

  return {
    loading: areProductFeaturesLoading || isFetchingThirdPartyOrgId,
    isEnabled: isEnterpriseTermsOfUseModalEnabled,
  };
};

export default useEnterpriseTermsOfUseModal;
