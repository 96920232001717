import gql from 'graphql-tag';

import type { EnterpriseProgramsV1SlugQuery as EnterpriseProgramsV1SlugQueryResult } from 'bundles/naptimejs/resources/__generated__/EnterpriseProgramsV1';

export type EnterpriseProgramBySlugQueryResult = EnterpriseProgramsV1SlugQueryResult;

export type EnterpriseProgramBySlugQueryVariables = {
  programSlug: string;
};

export const ThirdPartyOrgIdFromProgramSlugQuery = gql`
  query ProgramBySlug($programSlug: String!) {
    EnterpriseProgramsV1 @naptime {
      slug(
        slug: $programSlug
        eventualConsistency: true
        includes: "browsingExperience, thirdPartyOrg, contractTags, contract, curriculum"
      ) {
        linked {
          thirdPartyOrganizationsV1 {
            id
          }
        }
      }
    }
  }
`;
