import type { Cml_RenderableHtmlMetadata } from '__generated__/graphql-types-do-not-use';

import { VIDEO_RESOLUTIONS } from 'bundles/asset-admin/constants/AssetTypes';
import { AssetTypeNames } from 'bundles/asset-admin/types/assets';
import type { VideoResolution } from 'bundles/asset-admin/types/assets';
import type { HtmlMetadata } from 'bundles/cml/legacy/types/Content';
import type { VideoResolutions } from 'bundles/cml/legacy/types/assets';
import { stripProtocolAndDomains } from 'bundles/cml/shared/components/asset/assetUtils';
import type {
  AssetData,
  EmbeddableAssetData,
  ImageAssetData,
  VideoAssetData,
} from 'bundles/cml/shared/types/assetDataTypes';
import { ELEMENT_NODE, parseDOM } from 'bundles/cml/shared/utils/domUtils';

const VIDEO_ASSET_CLASS_NAME = 'cml-asset-video';
const AUDIO_ASSET_CLASS_NAME = 'cml-asset-audio';
const PDF_ASSET_CLASS_NAME = 'cml-asset-pdf';

const imageElementToAssetData = (node: Element): ImageAssetData | undefined => {
  const url = node.getAttribute('src') || '';
  if (!url) {
    return undefined;
  }

  return {
    type: 'image',
    url,
    alt: node.getAttribute('alt') || '',
  };
};

const figureElementToAssetData = (figure: Element): ImageAssetData | undefined => {
  const nodes = Array.from(figure.childNodes);

  let figCaption: Element | undefined;
  let image: Element | undefined;

  nodes.forEach((node) => {
    if (node.nodeType !== ELEMENT_NODE) {
      return;
    }

    if (node.nodeName === 'img') {
      image = node as Element;
    } else if (node.nodeName === 'figcaption') {
      figCaption = node as Element;
    }
  });

  if (!image) {
    return undefined;
  }

  const description = figCaption?.textContent?.trim() || undefined;
  const assetData = imageElementToAssetData(image);
  if (!assetData) {
    return undefined;
  }

  return {
    ...assetData,
    description,
  };
};

const getVideoJSAttributes = (el: HTMLElement) => {
  const url = el.getAttribute('data-url') || '';
  const extension = el.getAttribute('data-extension') || '';
  const name = el.getAttribute('data-name') || '';
  const id = el.getAttribute('data-id') || '';
  return { id, url, extension, name };
};

const elementToVideoAssetData = (
  el: HTMLElement,
  metadata: HtmlMetadata | Cml_RenderableHtmlMetadata | null | undefined,
  videoSubtitlesEnabled: boolean
): VideoAssetData | undefined => {
  const videoJSAttributes = getVideoJSAttributes(el);
  const { id, url } = videoJSAttributes;
  if (!id || !url) {
    return undefined;
  }
  const subtitleTracks =
    videoSubtitlesEnabled && metadata && 'assetData' in metadata
      ? metadata?.assetData?.find((assetData) => assetData.assetId === id)?.subtitles || []
      : [];

  const resolutions = VIDEO_RESOLUTIONS.reduce((result, resolution: VideoResolution) => {
    const mp4VideoUrl = el.getAttribute(`data-resolution-${resolution}-mp4`) || url;
    const webMVideoUrl = el.getAttribute(`data-resolution-${resolution}-webm`) || url;
    const previewImageUrl = el.getAttribute(`data-preview-thumbnail-${resolution}`) || '';

    result[resolution] = { previewImageUrl, webMVideoUrl, mp4VideoUrl }; // eslint-disable-line no-param-reassign
    return result;
  }, {} as VideoResolutions);
  return {
    ...videoJSAttributes,
    type: AssetTypeNames.VIDEO,
    subtitleTracks: stripProtocolAndDomains(subtitleTracks),
    resolutions,
  };
};

const elementToAudioAssetData = (el: HTMLElement): AssetData | undefined => {
  const child = el.childNodes[0] as HTMLElement;
  const label = (child?.nodeType === ELEMENT_NODE && child?.getAttribute('aria-label')) || undefined;

  const assetData = getVideoJSAttributes(el);
  if (!assetData.id || !assetData.url) {
    return undefined;
  }

  return {
    ...assetData,
    type: 'audio',
    label,
  };
};

const elementToPdfAssetData = (el: HTMLElement): EmbeddableAssetData | undefined => {
  const assetData = elementToGenericAssetData(el);

  if (!assetData) {
    return undefined;
  }

  const embedEnabled = el.getAttribute('data-embed-enabled') || '';
  const embedPageStart = el.getAttribute('data-embed-page-start') || '';
  const embedPageEnd = el.getAttribute('data-embed-page-end') || '';

  return {
    ...assetData,
    embeddable: embedEnabled === 'true',
    startPage: Number(embedPageStart) || undefined,
    endPage: Number(embedPageEnd) || undefined,
  };
};

const getUrl = (el: HTMLElement) => {
  const url = el.getAttribute('data-url');
  if (url) {
    return url;
  }

  const href = el.getAttribute('href');
  if (href) {
    return href;
  }

  const anchorNode = Array.from(el.childNodes).find((child) => {
    return child.nodeType === ELEMENT_NODE && child?.nodeName === 'a';
  });

  if (anchorNode) {
    const anchorEl = anchorNode as HTMLAnchorElement;
    return anchorEl.getAttribute('href') || '';
  }

  return '';
};

const elementToGenericAssetData = (el: HTMLElement): AssetData | undefined => {
  const id = el.getAttribute('data-id') || '';
  const url = getUrl(el);

  if (!id || !url) {
    return undefined;
  }

  const label = el.getAttribute('aria-label') || undefined;
  const name = el.getAttribute('data-name') || label || '';
  const extension = el.getAttribute('data-extension') || '';
  const assetType = el.getAttribute('data-asset-type') || '';

  return {
    id,
    type: assetType === 'pdf' ? 'pdf' : 'generic',
    url,
    name,
    extension,
    label,
  };
};

const getAssetDataFromElement = (
  el: HTMLElement,
  metadata: HtmlMetadata | Cml_RenderableHtmlMetadata | null | undefined,
  videoSubtitlesEnabled: boolean
): AssetData | undefined => {
  const className = el.getAttribute('class') ?? '';
  if (className.includes(VIDEO_ASSET_CLASS_NAME)) {
    return elementToVideoAssetData(el, metadata, videoSubtitlesEnabled);
  }

  if (className.includes(AUDIO_ASSET_CLASS_NAME)) {
    return elementToAudioAssetData(el);
  }
  if (className.includes(PDF_ASSET_CLASS_NAME)) {
    return elementToPdfAssetData(el);
  }

  return elementToGenericAssetData(el);
};

export const getAssetData = (
  renderableHtml: string | null | undefined,
  renderableHtmlMetadata: HtmlMetadata | Cml_RenderableHtmlMetadata | null | undefined,
  videoSubtitlesEnabled?: boolean
): { assets: Record<string, AssetData | VideoAssetData | undefined>; images: (ImageAssetData | undefined)[] } => {
  if (!renderableHtml) {
    return { assets: {}, images: [] };
  }

  const nodes = parseDOM(renderableHtml, 'text/html');

  const images: (ImageAssetData | undefined)[] = [];
  const assets: Record<string, AssetData | undefined> = {};

  nodes.forEach((node: ChildNode) => {
    const element = node as HTMLElement;
    if (element.nodeType !== ELEMENT_NODE) {
      return;
    }

    if (element.tagName === 'img') {
      images.push(imageElementToAssetData(element));
      return;
    }

    if (element.tagName === 'figure') {
      images.push(figureElementToAssetData(element));
      return;
    }

    const assetType = element.getAttribute('data-asset-type') ?? '';
    if (element.tagName === 'div' && assetType) {
      const asset = getAssetDataFromElement(element, renderableHtmlMetadata, !!videoSubtitlesEnabled);
      if (asset) {
        assets[asset.id] = asset;
      }
    }
  });

  return { images, assets };
};
