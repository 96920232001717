/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import type { Cml_SubtitleTrack } from '__generated__/graphql-types-do-not-use';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import { typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { AttachmentIcon, NoteIcon } from '@coursera/cds-icons';

import { VIDEO_RESOLUTIONS } from 'bundles/asset-admin/constants/AssetTypes';
import { AssetTypeNames } from 'bundles/asset-admin/types/assets';
import CMLAudioJS from 'bundles/cml/legacy/components/CMLAudioJS';
import type { VideoDataAttributes, VideoResolutions } from 'bundles/cml/legacy/types/assets';
import { isPDfEmbedEnabled, isSupportedAudioAsset, isSupportedVideoAsset } from 'bundles/cml/legacy/utils/AssetUtils';
import { EmbeddablePdfAsset } from 'bundles/cml/shared/components/asset/EmbeddablePdfAsset';
import { subtitleTracksToDataAttribute } from 'bundles/cml/shared/components/asset/assetUtils';
import type { AssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const CMLVideoJSV2 = createLoadableComponent(() => import('bundles/cml/legacy/components/CMLVideoJSV2'));

const styles = {
  baseAsset: css`
    position: relative;
    outline: none;
    user-select: none;
  `,
  audioAsset: css`
    height: 54px !important;
    border-radius: 100px;
  `,
  videoAsset: css`
    cursor: pointer;
  `,
  nonMediaAsset: css`
    background-color: var(--cds-color-neutral-background-primary-weak);
    border-radius: 8px;
    height: 84px;
    padding: 0 var(--cds-spacing-300);
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      outline: 2px solid var(--cds-color-interactive-primary);
    }
  `,
  assetInfo: css`
    flex: 1;
    min-width: 0;
    flex-direction: column;
    white-space: nowrap;
    overflow-x: hidden;
    margin-left: var(--cds-spacing-200);
  `,
  assetName: css`
    ${typography2.subtitleMedium}
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  assetLabel: css`
    ${typography2.bodyPrimary}
    color: var(--cds-color-neutral-primary-weak);
    margin-top: var(--cds-spacing-50);
  `,
};

type MediaAssetProps = AssetProps & { style?: Interpolation<Theme> } & {
  restAssetProps?: VideoDataAttributes;
};

const MediaAsset = (props: MediaAssetProps) => {
  const { id, assetData, style, ...restAssetProps } = props;

  if (!assetData) {
    return null;
  }

  const { extension, name, type: assetType, url } = assetData;
  return (
    <div>
      <div
        className={`cml-asset cml-asset-${assetType} cml-media-asset`}
        data-e2e={`cml-asset cml-asset-${assetType} cml-media-asset`}
        data-id={id}
        data-url={url}
        data-extension={extension}
        data-name={name}
        css={style}
        {...restAssetProps}
      />
    </div>
  );
};

const AudioAsset: React.FC<AssetPropsBase> = (props) => {
  const { id, assetData } = props;
  return (
    <CMLAudioJS>
      <MediaAsset id={id} assetData={assetData} style={styles.audioAsset} />
    </CMLAudioJS>
  );
};

const VideoAsset: React.FC<AssetPropsVideo> = (videoAssetProps) => {
  const { assetData, id, subtitleTracks, videoResolutions } = videoAssetProps;
  const videoDataAttributes: VideoDataAttributes = {};

  const dataSubtitleTracks = subtitleTracksToDataAttribute(subtitleTracks);
  if (dataSubtitleTracks) {
    videoDataAttributes['data-subtitle-tracks'] = dataSubtitleTracks;
  }

  if (videoResolutions) {
    VIDEO_RESOLUTIONS.forEach((resolution) => {
      const videoUrls = videoResolutions[resolution];
      videoDataAttributes[`data-resolution-${resolution}-mp4`] = videoUrls.mp4VideoUrl;
      videoDataAttributes[`data-resolution-${resolution}-webm`] = videoUrls.webMVideoUrl;
      videoDataAttributes[`data-preview-thumbnail-${resolution}`] = videoUrls.previewImageUrl;
    });
  }

  return (
    <CMLVideoJSV2>
      <MediaAsset id={id} assetData={assetData} {...videoDataAttributes} style={styles.videoAsset} />
    </CMLVideoJSV2>
  );
};

const NonMediaAsset: React.FC<AssetPropsBase> = (props) => {
  const { assetData, id } = props;

  if (!assetData) {
    return null;
  }

  const { extension, name, type: assetType, url } = assetData;
  const fileExtension = extension || name.split('.').splice(-1)[0] || '';
  const assetLabel = `${fileExtension.toUpperCase()} File`;
  const AssetIcon = assetType === AssetTypeNames.PDF ? NoteIcon : AttachmentIcon;

  return (
    <div
      className={`cml-asset cml-asset-${assetType} cml-nonmedia-asset`}
      data-e2e={`cml-asset cml-asset-${assetType} cml-nonmedia-asset`}
      data-id={id}
      data-url={url}
      data-extension={fileExtension}
      data-name={name}
      css={styles.nonMediaAsset}
    >
      <div data-e2e="asset-icon">
        <AssetIcon
          size="large"
          title={
            assetType === 'generic'
              ? _t(`Download #{assetLabel}`, { assetLabel })
              : _t(`View #{assetLabel}`, { assetLabel })
          }
        />
      </div>
      <div css={styles.assetInfo}>
        <div css={styles.assetName} data-e2e="asset-name">
          {name}
        </div>
        <div css={styles.assetLabel} data-e2e="asset-label">
          {assetLabel}
        </div>
      </div>
    </div>
  );
};

type AssetPropsBase = {
  id: string;
  assetData: AssetData;
  assetElement?: AssetElement;
  onOpenMenu?: () => void;
};

type AssetPropsVideo = AssetPropsBase & {
  videoResolutions: VideoResolutions;
  subtitleTracks: Cml_SubtitleTrack[];
};

export type AssetProps = AssetPropsBase | AssetPropsVideo;

export const isAssetPropsVideo = (assetProps: AssetProps): assetProps is AssetPropsVideo => {
  return assetProps.assetData?.type === AssetTypeNames.VIDEO;
};

const AssetV2: React.FC<AssetProps> = (props) => {
  const { id, assetElement, assetData } = props;

  // key is necessary to re-mount the component once asset data is loaded
  const nodeKey = `asset-node-${id}`;

  const renderInner = () => {
    // only render audio/video assets when the extension is supported by our system
    if (isSupportedAudioAsset(assetData)) {
      return <AudioAsset key={nodeKey} id={id} assetData={assetData} />;
    } else if (isAssetPropsVideo(props) && isSupportedVideoAsset(assetData)) {
      return (
        <VideoAsset
          key={nodeKey}
          id={id}
          assetData={assetData}
          subtitleTracks={props.subtitleTracks}
          videoResolutions={props.videoResolutions}
        />
      );
    } else if (assetElement && isPDfEmbedEnabled(assetElement)) {
      return <EmbeddablePdfAsset assetData={assetData} assetElement={assetElement} />;
    } else {
      return <NonMediaAsset key={nodeKey} id={id} assetData={assetData} />;
    }
  };

  return (
    <div
      className="asset-wrapper"
      data-testid="asset-v2-wrapper"
      css={styles.baseAsset}
      onClickCapture={props.onOpenMenu}
    >
      {renderInner()}
    </div>
  );
};

export default AssetV2;
