import { values } from 'lodash';

import type { AcceptedAssetTypeMap, VideoResolution } from '../types/assets';

// https://stackoverflow.com/questions/6977544/rar-zip-files-mime-type/7027582#7027582
// https://www.iana.org/assignments/media-types/media-types.xhtml
// https://mimeapplication.net/x-zip
// https://mimeapplication.net/x-compress
export const ZIP_FILE_MIME_TYPES = [
  'application/zip',
  'application/x-zip',
  'application/x-compress',
  'application/x-compressed',
  'application/x-zip-compressed',
  'multipart/x-zip',
  '.zip',

  // Do not add `application/octet-stream` as it will also allow EXE uploads.
];

export const XML_MIME_TYPES = ['text/xml', '.xml'];

export const ZIP_FILE_TYPES = {
  types: ZIP_FILE_MIME_TYPES,
  extensions: ['.zip'],
  label: 'ZIP',
};

export const XML_TYPES = {
  types: XML_MIME_TYPES,
  extensions: ['.xml'],
  label: 'XML',
};

export const TEMPLATE_TYPES = {
  types: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // we ONLY accept xlsx (no csv, xls, etc.)
  ],
  extensions: ['.xlsx'],
  label: 'Microsoft Excel (.xlsx)',
};

export const TAR_GZ_TYPES = {
  types: ['application/x-gzip', 'application/gzip', 'application/tar'],
  extensions: ['.gz'],
  label: 'edX or OpenEdX (.tar.gz)',
};

export const IMSCC_TYPES = {
  types: [
    'application/octet-stream', // OS X, dropbox, onedrive
    'application/x-zip', // google drive
  ],
  extensions: ['.imscc'],
  label: 'Canvas (IMS Common Cartridge)',
};

export const DOCX_TYPES = {
  types: [
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.google-apps.document',
  ],
  extensions: ['.docx', ''],
  label: 'Microsoft Word (.docx)',
};

export const YAML_TYPES = {
  types: ['text/yaml', '.yml', '.yaml'],
  extensions: ['.yml', '.yaml'],
  label: 'Coursera YAML/Markdown (.yaml, .yml)',
};

export const QTI_TYPES = {
  types: [...ZIP_FILE_TYPES.types, ...XML_TYPES.types],
  extensions: [...ZIP_FILE_TYPES.extensions, ...XML_TYPES.extensions],
  label: 'IMS QTI (zip file or question file)',
};

// TODO: fix type generated from this to have different name
const ASSET_TYPES: AcceptedAssetTypeMap = {
  Image: {
    name: 'image',
    types: ['image/*'],
  },

  Video: {
    name: 'video',
    types: [
      'video/mp4',
      'video/x-m4v',
      'video/x-flv',
      'video/*',
      // these file types aren't universally/officially supported mimetypes (see https://www.iana.org/assignments/media-types/media-types.xhtml) so we need to provide the extension to get input type=file "accept" property to work consistently
      '.mkv',
      '.flv',
    ],
    extensions: ['mp4', 'mov', 'mkv', 'flv'],
  },

  Subtitle: {
    name: 'subtitle',
    types: ['text/srt', 'text/vtt', '.srt', '.vtt'],
    extensions: ['srt', 'vtt'],
  },

  Audio: {
    name: 'audio',
    // supported types currently, other audio formats will be treated as 'generic' on upload
    types: ['audio/mpeg', 'audio/mpeg3', 'audio/mp4', 'audio/wav'],
    extensions: ['mp3', 'wav', 'mp4'],
  },

  Pdf: {
    name: 'pdf',
    types: ['application/pdf'],
  },

  Generic: {
    name: 'generic',
    types: [],
  },

  Authoring: {
    name: 'authoring',
    // in macOS catalina, our yaml ulba files aren't recognized as text/yaml
    types: [...XML_TYPES.types, ...ZIP_FILE_TYPES.types, ...YAML_TYPES.types, ...DOCX_TYPES.types],
    extensions: [...YAML_TYPES.extensions, ...DOCX_TYPES.extensions],
    label: [DOCX_TYPES.label, YAML_TYPES.label, XML_TYPES.label, ZIP_FILE_TYPES.label].join(', '),
  },

  CSV: {
    name: 'csv',
    types: ['text/csv', '.csv'],
    extensions: ['.csv'],
  },

  ulbaCourseImports: {
    name: 'ulbaCourseImports',
    types: [...TEMPLATE_TYPES.types, ...TAR_GZ_TYPES.types, ...IMSCC_TYPES.types],
    extensions: [...TEMPLATE_TYPES.extensions, ...TAR_GZ_TYPES.extensions, ...IMSCC_TYPES.extensions],
    label: [TEMPLATE_TYPES.label, TAR_GZ_TYPES.label, IMSCC_TYPES.label].join(', '),
  },
  ulbaExistingCourseImports: {
    name: 'ulbaExistingCourseImports',
    types: TEMPLATE_TYPES.types,
    extensions: TEMPLATE_TYPES.extensions,
    label: 'Microsoft Excel (.xlsx)',
  },
  UlbaSga: {
    name: 'ulbaSga',
    types: [...DOCX_TYPES.types, ...YAML_TYPES.types, ...QTI_TYPES.types],
    extensions: [...YAML_TYPES.extensions, ...DOCX_TYPES.extensions, ...QTI_TYPES.extensions],
    label: [DOCX_TYPES.label, YAML_TYPES.label, QTI_TYPES.label].join(', '),
  },

  UlbaPeerReview: {
    name: 'ulbaPeerReview',
    types: [...DOCX_TYPES.types],
    extensions: [...DOCX_TYPES.extensions],
    label: DOCX_TYPES.label,
  },
};

export const VIDEO_RESOLUTIONS: Array<VideoResolution> = ['240p', '360p', '540p', '720p'];

export const ALL_TYPES = values(ASSET_TYPES).reduce(
  (allTypes: Array<string>, asset) => [...asset.types, ...allTypes],
  []
);

export default ASSET_TYPES;
