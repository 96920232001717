import type {
  Cml_Cml,
  Cml_RenderableHtmlMetadata,
  CmlContent as GraphqlCmlContent,
} from '__generated__/graphql-types-do-not-use';
import { filter, find, includes, map } from 'lodash';

import CMLParser from 'bundles/cml/legacy/models/CMLParser';
import type { HtmlMetadata, CmlContent as NaptimeCmlContent } from 'bundles/cml/legacy/types/Content';
import type { CmlContent as ReshapedCmlContent } from 'bundles/cml/shared/types/CmlContent';

const { BLOCK_TYPES, SUPPORTED_BLOCKS } = CMLParser;

const { Image, Audio, Asset, Code, Widget } = BLOCK_TYPES;
const NON_TEXT_BLOCK_TYPES = [Image, Audio, Asset, Code, Widget];

function isNaptimeCmlContent(
  cmlObject: ReshapedCmlContent | Cml_Cml | NaptimeCmlContent
): cmlObject is NaptimeCmlContent {
  // TypeScript should prevent this, but some (JS) unit tests use strings to mock CML:
  if (typeof cmlObject !== 'object') {
    return false;
  }

  return 'typeName' in cmlObject || 'definition' in cmlObject;
}

function isGraphqlCmlContent(
  cmlObject: ReshapedCmlContent | Cml_Cml | NaptimeCmlContent
): cmlObject is GraphqlCmlContent {
  // TypeScript should prevent this, but some (JS) unit tests use strings to mock CML:
  if (typeof cmlObject !== 'object') {
    return false;
  }

  return cmlObject && '__typename' in cmlObject && cmlObject.__typename === 'CmlContent';
}

function isGraphqlRenderableCmlContent(
  cmlObject: ReshapedCmlContent | Cml_Cml | NaptimeCmlContent
): cmlObject is Cml_Cml {
  // TypeScript should prevent this, but some (JS) unit tests use strings to mock CML:
  if (typeof cmlObject !== 'object') {
    return false;
  }

  return cmlObject && '__typename' in cmlObject && cmlObject.__typename === 'Cml_Cml';
}

const CMLUtils = {
  /**
   * Create a new CML object.
   * @param {string} cmlValue CML text
   * @param {string} dtdId DTD identifier for the CML
   */
  createCml: (cmlValue?: string, dtdId?: string): ReshapedCmlContent => {
    return {
      __typename: 'CmlContent',
      dtdId: dtdId || '',
      cmlValue: cmlValue || CMLParser.EMPTY_CML,
    };
  },

  /**
   * Create a new Naptime CML object.
   * @param {string} cmlValue CML text
   * @param {string} dtdId DTD identifier for the CML
   */
  create: (cmlValue?: string, dtdId?: string): NaptimeCmlContent => {
    return {
      typeName: 'cml',
      definition: {
        dtdId: dtdId || '',
        value: cmlValue || CMLParser.EMPTY_CML,
      },
    };
  },

  /**
   * Get DTD id associated with the CML object.
   * @param {object} cmlObject
   */
  getDtdId: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): string => {
    if (!cmlObject) {
      return '';
    }
    if (isNaptimeCmlContent(cmlObject)) {
      return cmlObject.definition?.dtdId ?? '';
    }
    return cmlObject.dtdId ?? '';
  },

  /**
   * Get cml value associated with the CML object.
   * @param {object} cmlObject
   */
  getValue: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): string => {
    if (!cmlObject) {
      return '';
    }
    if (isNaptimeCmlContent(cmlObject)) {
      return cmlObject.definition?.value ?? '';
    }
    return cmlObject.cmlValue;
  },

  getRenderableHtml: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): string | null => {
    if (!cmlObject) {
      return null;
    }
    if (isNaptimeCmlContent(cmlObject)) {
      return cmlObject.definition?.renderableHtmlWithMetadata?.renderableHtml ?? null;
    }
    return cmlObject.htmlWithMetadata?.html ?? null;
  },

  getRenderableHtmlMetadata: (
    cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null
  ): HtmlMetadata | Cml_RenderableHtmlMetadata | null => {
    if (!cmlObject) {
      return null;
    }

    if (isNaptimeCmlContent(cmlObject)) {
      return cmlObject.definition?.renderableHtmlWithMetadata?.metadata ?? null;
    }

    if (isGraphqlCmlContent(cmlObject)) {
      return cmlObject.htmlWithMetadata?.metadata ?? null;
    }

    if (isGraphqlRenderableCmlContent(cmlObject)) {
      return cmlObject.htmlWithMetadata?.metadata ?? null;
    }

    return null;
  },

  getInnerText: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null) => {
    const cmlValue = CMLUtils.getValue(cmlObject);
    return CMLUtils.getInnerTextFromValue(cmlValue);
  },

  getInnerTextFromValue: (cmlValue: string) => {
    const parser = new CMLParser(cmlValue);

    return CMLParser.getInnerText(parser.getRoot()) || '';
  },

  /**
   * Return true if the CML does not have any non-empty blocks.
   * @param {object} cmlObject CML object.
   */
  isEmpty: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): boolean => {
    const cmlText = CMLUtils.getValue(cmlObject);
    const parser = new CMLParser(cmlText);
    const blocks = parser.getBlocks();

    if (!blocks || blocks.length === 0) {
      return true;
    }

    const nonEmptyBlock = find(blocks, (block) => {
      const blockType = CMLParser.getBlockType(block);
      const text = CMLParser.getInnerText(block);

      return text !== '' || NON_TEXT_BLOCK_TYPES.indexOf(blockType) !== -1;
    });

    return nonEmptyBlock === undefined;
  },

  /**
   * Get the length of the textual context of the given cml
   * @type {object} cmlObject
   */
  getLength: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): number => {
    return cmlObject ? CMLUtils.getInnerText(cmlObject).length : 0;
  },

  /**
   * Get the length of the textual context of the given cml value
   */
  getLengthForString: (cmlValue?: string | null | undefined): number => {
    /* Note that this function now trims the text to match the BE length validation.
    https://github.com/webedx-spark/infra-services/blob/e2568c4fee75b81ec2be860f5601168e12772dbe/libs/authoringLib/src/main/scala/org/coursera/authoring/validation/AtomicValidationHelper.scala#L109 */
    return cmlValue ? CMLUtils.getInnerTextFromValue(cmlValue).trim().length : 0;
  },

  /**
   * Calculate the number of words of the textual content of the given cml.
   */
  getWordCount: (cmlObject?: ReshapedCmlContent | NaptimeCmlContent | null): number => {
    const wordsSeparatorsRegex = /\s+/;
    return CMLUtils.getInnerText(cmlObject).split(wordsSeparatorsRegex).filter(Boolean).length;
  },

  /**
   * Return true if given object is a valid CML object.
   */
  isCML: (maybeCmlObject?: ReshapedCmlContent | NaptimeCmlContent | string): boolean => {
    if (!maybeCmlObject) {
      return false;
    }

    // HTML is returned as a string and not an object.
    return (
      typeof maybeCmlObject !== 'string' &&
      (isNaptimeCmlContent(maybeCmlObject) ? maybeCmlObject.typeName === 'cml' : 'cmlValue' in maybeCmlObject)
    );
  },

  replaceVariable: (
    cmlObject: ReshapedCmlContent | NaptimeCmlContent,
    variable: string,
    name: string
  ): ReshapedCmlContent | NaptimeCmlContent => {
    if (isNaptimeCmlContent(cmlObject)) {
      const cmlObjectCopy = Object.assign({}, cmlObject);
      cmlObjectCopy.definition.value = cmlObjectCopy.definition.value.replace(`%${variable}%`, name);
      return cmlObjectCopy;
    }
    const cmlObjectCopy = Object.assign({}, cmlObject);
    cmlObjectCopy.cmlValue = cmlObjectCopy.cmlValue.replace(`%${variable}%`, name);
    return cmlObjectCopy;
  },

  /**
   * Get list of blocks that are not supported for the given CML.
   * @param {string} cml
   */
  getUnsupportedBlocks: (cml?: ReshapedCmlContent | NaptimeCmlContent | null) => {
    const cmlText = CMLUtils.getValue(cml);
    const parser = new CMLParser(cmlText);
    const blocks = parser.getBlocks();

    return filter(blocks, (block) => {
      const blockType = CMLParser.getBlockType(block);
      return !includes(SUPPORTED_BLOCKS, blockType);
    });
  },

  isHeadingBlock: (block: Element, level = '1'): boolean => {
    return block?.tagName === 'heading' && block?.getAttribute('level') === level;
  },

  /**
   * Returns the first block node in the CML when exists
   * @param {object} cml CML object.
   */
  getFirstBlock: (cml?: ReshapedCmlContent | NaptimeCmlContent | null): Element | null => {
    const cmlText = CMLUtils.getValue(cml) || '';
    const parser = new CMLParser(cmlText);
    const blocks = parser.getBlocks();

    return blocks[0] ?? null;
  },

  /**
   * Comparing two CML objects for equality.
   * @param {object} cmlA CML object.
   * @param {object} cmlB CML object.
   */
  areCmlValuesEqual: (cmlA?: ReshapedCmlContent | NaptimeCmlContent, cmlB?: ReshapedCmlContent | NaptimeCmlContent) => {
    if (cmlA === cmlB) {
      return true;
    } else if (!cmlA || !cmlB) {
      return false;
    } else {
      return CMLUtils.getValue(cmlA) === CMLUtils.getValue(cmlB);
    }
  },

  /**
   * Comparing two lists of CML objects for equality.
   * @param {object} cmlA list of CML objects.
   * @param {object} cmlB list of CML objects.
   */
  areCmlListsEqual: (
    cmlA?: Array<ReshapedCmlContent | NaptimeCmlContent>,
    cmlB?: Array<ReshapedCmlContent | NaptimeCmlContent>
  ) => {
    if (cmlA === cmlB) {
      return true;
    } else if (!cmlA || !cmlB) {
      return false;
    } else if (cmlA.length !== cmlB.length) {
      return false;
    } else {
      return cmlA.reduce((allItemsAreEqual, currentCML, currentIndex) => {
        return allItemsAreEqual && CMLUtils.areCmlValuesEqual(currentCML, cmlB[currentIndex]);
      }, true);
    }
  },
};

export function createCmlContentDefinitionValueString(text = '') {
  return `<co-content><text>${text}</text></co-content>`;
}

export function getCmlBlockTypes(cml?: ReshapedCmlContent | NaptimeCmlContent | null) {
  const cmlText = CMLUtils.getValue(cml);
  const parser = new CMLParser(cmlText);
  const cmlBlocks = parser.getBlocks();
  return map(cmlBlocks, (block) => CMLParser.getBlockType(block));
}

export default CMLUtils;

export const {
  create,
  getDtdId,
  getValue,
  getRenderableHtml,
  getRenderableHtmlMetadata,
  getInnerText,
  isEmpty,
  getLength,
  getWordCount,
  isCML,
  replaceVariable,
  getUnsupportedBlocks,
  isHeadingBlock,
  areCmlValuesEqual,
  areCmlListsEqual,
  getFirstBlock,
} = CMLUtils;
