import type { Cml_SubtitleTrack } from '__generated__/graphql-types-do-not-use';

import { AssetTypeNames } from 'bundles/asset-admin/types/assets';
import type { AssetTypeName } from 'bundles/asset-admin/types/assets';
import type { VideoResolutions } from 'bundles/cml/legacy/types/assets';

type AssetDataBase = {
  type: AssetTypeName;
  url: string;
};

export type ImageAssetData = AssetDataBase & {
  alt?: string;
  description?: string;
};

export type AssetData = AssetDataBase & {
  id: string;
  name: string;
  extension: string;
  label?: string;
};

export type AudioAssetData = AssetData & {
  type: 'audio';
};

export type VideoAssetData = AssetData & {
  type: 'video';
  subtitleTracks?: Array<Cml_SubtitleTrack>;
  resolutions: VideoResolutions;
};

export type EmbeddableAssetData = AssetData & {
  embeddable: boolean;
  startPage?: number;
  endPage?: number;
};

export type MediaAssetData = AudioAssetData | VideoAssetData;

export const isAudioAssetData = (assetData: AssetData): assetData is AudioAssetData => {
  return assetData.type === AssetTypeNames.AUDIO;
};

export const isVideoAssetData = (assetData: AssetData): assetData is VideoAssetData => {
  return assetData.type === AssetTypeNames.VIDEO;
};

export const isMediaAssetData = (assetData: AssetData): assetData is MediaAssetData => {
  return isAudioAssetData(assetData) || isVideoAssetData(assetData);
};
