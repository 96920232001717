import * as React from 'react';

import { branch, compose, renderComponent, withProps } from 'recompose';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { isAuthenticatedUser } from 'js/lib/user';

import { LoadingSection } from '@coursera/cds-core';

import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import useEnterpriseTermsOfUseModal from 'bundles/user-consent/hooks/useEnterpriseTermsOfUseModal';
import useMarketingConsentData from 'bundles/user-consent/hooks/useMarketingConsentData';

const LoadableGDPRPage = createLoadableComponent(() => import('bundles/user-consent/components/GDPRPage'));
const LoadableCCPAPage = createLoadableComponent(() => import('bundles/user-consent/components/CCPAPage'));

type MarketingConsentProps = {
  shouldShowCCPAPage: boolean;
  shouldShowGDPRPage: boolean;
  isEnterpriseAdmin: boolean;
  shouldShowMarketingConsent: boolean;
};

type LoadingProps = {
  isEnterpriseTermsOfUseModalEnabledLoading: boolean;
};

function useConsentEligibility() {
  const shouldSkipMarketingConsentQuery = !isAuthenticatedUser();

  const {
    shouldShowCCPAMarketingConsent,
    shouldShowGDPRMarketingConsent: shouldShowGDPRPage,
    isEnterpriseAdmin,
  } = useMarketingConsentData(undefined, shouldSkipMarketingConsentQuery);

  const shouldShowCCPAPage = !shouldShowLegalConsolidationAuthFlow() && shouldShowCCPAMarketingConsent;
  const isMarketingConsentRequired = shouldShowCCPAPage || shouldShowGDPRPage;

  const shouldSkipLfoQuery = shouldSkipMarketingConsentQuery || !isMarketingConsentRequired;

  const { isEnabled: isEnterpriseTermsOfUseModalEnabledForOrg, loading: isEnterpriseTermsOfUseModalEnabledLoading } =
    useEnterpriseTermsOfUseModal(shouldSkipLfoQuery);

  const shouldShowMarketingConsent = !isEnterpriseTermsOfUseModalEnabledForOrg && isMarketingConsentRequired;

  return {
    shouldShowMarketingConsent,
    shouldShowCCPAPage,
    shouldShowGDPRPage,
    isEnterpriseAdmin,
    isEnterpriseTermsOfUseModalEnabledLoading,
  };
}

function MarketingConsent({
  shouldShowCCPAPage,
  shouldShowGDPRPage,
  isEnterpriseAdmin,
}: Omit<MarketingConsentProps, 'shouldShowMarketingConsent'>) {
  if (shouldShowCCPAPage) {
    return <LoadableCCPAPage isEnterpriseAdmin={isEnterpriseAdmin} />;
  } else if (shouldShowGDPRPage) {
    return <LoadableGDPRPage isEnterpriseAdmin={isEnterpriseAdmin} />;
  }

  return null;
}

export default (Component: React.ComponentType<MarketingConsentProps>) =>
  compose<MarketingConsentProps, {}>(
    withProps(() => {
      const eligibilityStatus = useConsentEligibility();
      return eligibilityStatus;
    }),
    branch<LoadingProps>(
      ({ isEnterpriseTermsOfUseModalEnabledLoading }) => !!isEnterpriseTermsOfUseModalEnabledLoading,
      renderComponent(LoadingSection)
    ),
    branch<Pick<MarketingConsentProps, 'shouldShowMarketingConsent'>>(
      ({ shouldShowMarketingConsent }) => !!shouldShowMarketingConsent,
      renderComponent(MarketingConsent)
    )
  )(Component);
